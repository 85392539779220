import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo";

const BikeShelters = () => {
    return (
        <Layout>
                <Seo
      title="Bike Shelters & Compounds - BIKESAFE"
      description="Vorbei die Zeit, als Sie Ihr Fahrrad an einen arglosen Baum im Park oder einen Laternenpfosten auf der Straße ketten mussten. Ein Fahrradunterstand oder ein Fahrradparksystem kann in den meisten öffentlichen Bereichen aufgestellt werden, aber da immer mehr Personen ein Fahrrad besitzen, wachsen auch der Bedarf und die Erwartungen Fahrradbegeisterter an zugängliche Fahrradunterstände."
    />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center uppercase">
                        Fahrradunterstände und Fahrradüberdachungen
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <p className="flow-text">
                        Vorbei die Zeit, als Sie Ihr Fahrrad an einen arglosen Baum im Park oder einen Laternenpfosten auf der Straße ketten mussten. Ein Fahrradunterstand oder ein Fahrradparksystem kann in den meisten öffentlichen Bereichen aufgestellt werden, aber da immer mehr Personen ein Fahrrad besitzen, wachsen auch der Bedarf und die Erwartungen Fahrradbegeisterter an zugängliche Fahrradunterstände.
                        </p>
                        <p className="flow-text"> Unsere Fahrradunterstände sind haltbar, modern und extrem praktisch. Nach welcher Größe und welchem Stil Sie auch immer suchen: wir haben das richtigen Fahrradparksystem für Sie. Bei uns finden Sie Fahrradständer immer günstiger!</p>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                <hr/>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/233">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/233/homepage-233.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Fahrradunterstand New York</h3>
                            <p>Unterstand verzinkt oder mit Pulverbeschichtung in RAL-Farben erhältlich und inklusive verzinktem Reihenanlehnbügel. Übersteigsicheres Dach und Seitenrahmen mit 5 mm starken Polycarbonatplatten.</p>
                            <p className="shop-now">Ein Angebot bekommen</p>
                        </Link>
                    </Col>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/38">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/38/homepage-38.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Fahrradunterstand Arche</h3>
                            <p>Dieser Unterstand wurde entwickelt, um die doppelt so viele Fahrräder auf relativ geringem Raum in 2 Reihen mit Fahrradstellplätzen unterzubringen. Unterstand verzinkt oder mit Pulverbeschichtung...</p>
                            <p className="shop-now">Ein Angebot bekommen</p>
                        </Link>
                    </Col>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/22">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/22/homepage-22.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Fahrradunterstand Dresden</h3>
                            <p>Ein Unterstand, der sich dank vielseitigen Merkmalen perfekt für jedes Umfeld eignet. Unterstand aus Baustahl zum Aufschrauben. Unterstand verzinkt oder mit Polyester-Pulverbeschichtung in RAL-Farben...</p>
                            <p className="shop-now">Ein Angebot bekommen</p>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default BikeShelters;